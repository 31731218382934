<template>
  <div class="drawer-container" data-testid="drawer-container">
    <div class="drawer" data-testid="drawer">
      <div class="drawer__header">
        <h2 class="drawer__title" data-testid="drawer-title">
          {{ title }}
        </h2>
        <button class="drawer__close-button" data-testid="drawer-close-button" @click="handleClose">
          <Icon name="close" data-testid="drawer-close-button-icon" classes="drawer__close-button-icon" />
        </button>
      </div>
      <div class="drawer__content" :class="{ 'no-padding': withoutPadding }" data-testid="drawer-content">
        <slot></slot>
      </div>

      <div class="drawer__footer" data-testid="drawer-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';

export default {
  name: 'Drawer',
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    withoutPadding: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = '';
  },
};
</script>

<style lang="scss" scoped>
.drawer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($grey, 0.64);
  z-index: 10;
  display: flex;
  justify-content: flex-end;
}

.drawer {
  background-color: white;
  height: 100vh;
  width: 100%;
  max-width: 459px;
  display: flex;
  flex-direction: column;

  @media (max-width: $screen-xs) {
    position: fixed;
    bottom: 0;
    height: 80vh;
    border-radius: 4px 4px 0 0;
  }
}

.drawer__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid $grey-10;
}

.drawer__close-button-icon {
  font-size: 1rem;
}

.drawer__title {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  color: $grey;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 40px);
}

.drawer__close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawer__content {
  flex: 1;
  overflow-y: auto;
  padding: 24px;

  &.no-padding {
    padding: 0;
  }

  @media (max-width: $screen-xs) {
    padding: 16px;

    &.no-padding {
      padding: 0;
    }
  }
}

.drawer__footer {
  padding: 24px;

  @media (max-width: $screen-xs) {
    padding: 16px;
  }
}
</style>
